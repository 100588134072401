/*colors*/
:root {
  --bodyBg-color: #f4f9ff;
  --blue-color: #0756f8;
  --minBlue-color: #4985ff;
  --blue-2-color: #8e9aff;
  --blue-3-color: #deecff;
  --card-bg-color: #eaf2ff;
  --grey-darkest-color: #3a3a3a;
  --grey-dark-color: #6d6d6d;
  --grey-medium-color: #d6d4d4;
  --light-grey-color: #dee2e6;
  --lightPink-color: #d3c3ff33;
  --yellow-color: #e6c100;
  --black-color: #000000;
  --card-hover-color: #a8b1ff;
}
.tooltip-inner {
  background-color: #c7e0ff;
  color: #000000;
  font-size: 11px;
  font-weight: 500;
}
.mtb-4 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.border-sm-red {
  border: 1px solid red !important;
}

shadow {
  box-shadow: var(--blue-color);
  outline: auto;
}

.center-box {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.w-fit-content {
  width: fit-content !important;
}

.ml-auto {
  margin-left: auto;
}

.fa-apple {
  font-size: 15px;
}

.br-blue {
  border-right: 2px solid var(--blue-color);
}

.mr-3 {
  margin-right: 10px !important;
}

.tr-bottom-primary {
  border-bottom: 2px solid var(--blue-color);
}

.mx-width-700 {
  max-width: 44rem !important;
}

.th-w-25 th {
  width: 25%;
}

.play-icon {
  width: 17px;
}

.align-center {
  align-items: center;
}

.w-85 {
  width: 85%;
}

.z-index-unset {
  z-index: unset !important;
}
.bg-rigncolor {
  background-color: var(--bs-focus-ring-color);
  width: 70px;
  text-align: center;
  margin-bottom: 2px;
}
.fs-6 {
  font-size: 14px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.uploadedFile .input-group {
  padding: 8px;
  border-bottom: 1px solid;
}
.uploadedFile button {
  border: unset !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.welcome footer {
  position: fixed;
  background-color: var(--bodyBg-color);
  width: 100%;
  bottom: 0;
}
.welcome-list {
  text-align: center;
}

.welcome-center {
  padding-top: 16px;
  padding-bottom: 100px;
}

.welcome-list li {
  list-style: none;
  margin-left: 12px;
}

.welcome-list a {
  text-decoration: unset;
}

.welcome-address {
  @extend .welcome-list;
}

.fs-7 {
  font-size: 12px;
}

.fs-8 {
  font-size: 13px;
}

.fs-9 {
  font-size: 11px;
}

.w9form-pb1 {
  padding-bottom: 2px !important;
}

.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.fs-fw-5 {
  cursor: pointer;
  font-size: 22px;
  font-weight: 900;
  color: var(--blue-color);
  margin-left: 24px;
}
.custom-toastr {
  background-color: #0dcaf0;
  padding: 16px;
  border-radius: 3px;
  color: #ffff;
  font-weight: 500 !important;
  margin-left: 30px;
}
.toast-container {
  pointer-events: unset !important;
}
.success-toast {
  background-color: #0dcaf0 !important;
  color: #ffff;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 350px;
  border-radius: 6px;
  font-size: 14px;
}
.success-toast:hover {
  box-shadow: 0 0 12px #000000;
  cursor: pointer;
}
.input-group-text {
  border: unset !important;
  background: unset !important;
}

.support-filediv .input-group-text {
  border: 1px solid #dee2e6 !important;
  cursor: pointer;
  color: var(--grey-dark-color);
}
.support-filediv .pic-file-span {
  background: unset !important;
  color: var(--grey-dark-color);
}
.border-b-blue {
  border-bottom: 2px solid var(--blue-color) !important;
}

body {
  position: relative;
  font-family: "Poppins" !important;
  background-color: var(--bodyBg-color) !important;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.landingpage-data {
  margin-top: 37px;
  margin-left: 105px;
  margin-right: 205px;
}

.redirect-landingbtn {
  border-radius: 25px !important;
}

.landing-page-imgicon {
  width: 30px;
  margin-bottom: 10px;
}

// .landingpage-login {
//     background-image: url('../assets/image/login-background-image.png');
//     padding-bottom: 25px !important;
// }
.login-form {
  background-color: var(--card-bg-color);
  border-radius: 8px;
  height: fit-content;
}

.login-opinion-edge {
  margin-right: 40px;
  margin-bottom: 15px;
}

.login-opinion-edge .loginbtn {
  margin-top: 48px;
  width: 100%;
}

.visitor-id {
  color: var(--bodyBg-color) !important;
}

.panel-register img {
  pointer-events: none;
}

.password-eye {
  position: absolute;
  margin-left: 26rem;
  margin-top: -35px;
  cursor: pointer;
}

.recaptcha iframe {
  transform: scale(0.9); /* Adjust the scale as needed */
  transform-origin: 0 0;
}

.rc-anchor-error-msg-container {
  color: var(--grey-dark-color) !important;
}

.recaptcha iframe body .rc-anchor-light {
  background-color: #eaf2ff !important;
}

.nav-devider {
  width: 96%;
  height: 1px !important;
  margin-top: 0px !important;
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
}

.card-active {
  border: 4px solid var(--blue-color);
}

/*header nav */
.navbar-nav .nav-item {
  padding: 8px 16px !important;
  font-size: 14px !important;
}

.navbar-nav .nav-link {
  margin-left: 12px;
  margin-right: 12px;
}

.navbar-nav .nav-link:hover {
  border-radius: 4px;
  height: 100%;
  background-color: var(--lightPink-color);
  transition: 0.1s ease-in-out;
  transform: scale(1.1);
}

.navbar-nav .join-link {
  background-color: var(--blue-color);
  color: #ffffff !important;
  border-radius: 6px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

.navbar-nav .join-link:hover {
  color: var(--grey-darkest-color) !important;
}

.link-active {
  color: var(--blue-color) !important;
  font-weight: 600;
}

.link-active .nav-link {
  color: var(--blue-color) !important;
}

.link-active .join-link {
  color: #ffffff !important;
}

.notificationDate {
  // margin-left: 70px;
  font-size: 12px;
  font-weight: 500 !important;
}

.notificationDate i {
  font-size: 10px;
}

/*.logo {
    text-align: center;
    width: 3rem;
    min-width: 3rem;
    border-radius: 0.313rem;
    color: var(--blue-color);
    font-size: 24px;
    font-weight: 600;
  }
  
  .logo-name {
    color: var(--grey-dark-color);
    font-size: 24px;
    font-weight: 600;
  }*/
/* login/home page*/
.unsubscribeBtn {
  width: 150px;
  background-color: var(--blue-color) !important;
}

.form-input {
  font-size: 14px !important;
  border: none;
  color: var(--grey-dark-color) !important;
  background-color: var(--bodyBg-color);
  padding-left: unset;
}

.forminput-password {
  border: none;
  background: unset !important;
  outline: unset !important;
  box-shadow: unset !important;
  border-bottom: 2px solid var(--blue-color);
  color: var(--grey-dark-color) !important;
  font-size: 14px;
}

.form-heading {
  color: var(--blue-color);
  font-size: 24px;
  font-weight: 600;
  border-bottom: 2px solid var(--blue-color);
  margin-bottom: 55px;
  margin-top: 26px;
}

.form-heading h3 {
  font-weight: 600;
  font-size: 24px;
}

.form-label {
  color: var(--blue-color) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.remember-user {
  margin-top: 10px;
}

.remember-user .form-check-label {
  font-weight: 400;
  font-size: 12px;
  color: var(--grey-darkest-color);
}

.remember-user .forgot-password {
  font-weight: 400;
  font-size: 14px;
  color: var(--blue-color);
  text-decoration: none;
  cursor: pointer;
}

.forgot-password-box {
  padding: 16px;
  border-radius: 8px;
  margin: 8px;
  width: -webkit-fill-available;
  input:focus{
   outline: none !important;
   box-shadow: unset;
  }
}

.remember-user .forgot-password:hover {
  text-decoration: underline;
}

.landingpage-earn {
  color: var(--blue-color);
}

.password-div {
  padding: 8px;
}

.login-email {
  background: unset;
  border: unset;
  border-bottom: 2px solid var(--blue-color);
  padding-left: 0px !important;
  font-size: 14px;
  font-weight: 400;
}

.loginbtn {
  width: 118px;
  height: 29px;
  align-items: center;
  margin-left: 10px;
  padding-top: 3px;
  border: 1px solid var(--blue-color);
}

.reset-password {
  color: var(--blue-color);
  font-weight: 600;
}

.circle-check-div {
  font-size: 58px;
  color: green;
}

.verifymessage-box {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  border-radius: 8px;
  padding: 19px 10px;
}

.app-play {
  font-size: 12px;
  border: 1px solid var(--grey-darkest-color);
  border-radius: 6px;
  padding: 6px;
}

.available-app {
  gap: 14px;
}

// .app-play i{
//     font-size: 20px;
// }
.app-play a {
  text-decoration: none;
  color: var(--grey-dark-color);
  margin-left: 8px;
  font-weight: 800;
}

// .fs-4 {
//     font-size: 14px !important;
// }

.mt-5 {
  margin-top: 7rem !important;
}

.mb-5 {
  margin-bottom: 7rem !important;
}

.t-header {
  font-weight: 600 !important;
  font-size: 40px;
  color: var(--minBlue-color);
}

.landingpage-header {
  margin-top: 32px;
}

.t-subheader {
  gap: 72px;
  margin-top: 56px;
  font-size: 20px;
  color: var(--grey-darkest-color);
}

.joinpanel-link {
  margin-top: 69px !important;
  margin-bottom: 20px !important;
}

.joinpanel-link a {
  text-decoration: none;
  color: var(--blue-color);
  font-weight: 600;
  font-size: 16px;
}

.joinpanel-link a:hover {
  // border: 1px solid var(--blue-color);
  transition: 0.8s ease-in-out;
  transform: scale(1.2);
  text-decoration: underline;
}

.header {
  background-color: var(--bodyBg-color);
}

.offcanvas-close {
  display: none;
}

.badge-fit {
  font-size: 10px;
  bottom: 12px;
}

header .dropdown-menu {
  transform: unset !important;
  margin: unset !important;
  inset: 26px 2px auto auto !important;
  width: 300px;
  top: 100%;
  padding: 8px;
  height: 300px;
  background-color: var(--bodyBg-color);
  overflow: auto;
  padding-top: 0px;
}

.dropdown-content {
  font-size: 14px;
}

.notificationbody {
  box-shadow: 0px 0px 1px 0px;
  padding: 7px;
  margin-top: 6px;
}

.readtic {
  color: #0de50d !important;
}

.unreadtic {
  color: #f43232 !important;
}

.footer {
  // position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--bodyBg-color);
  height: 48px;
  align-items: center !important;
  // padding-top: 20px;
  font-size: 20px;
}

// .page-footer {
//     position: fixed;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height: 30px;
//     background-color: var(--bodyBg-color);
//     align-items: center !important;
// }

.panel-register {
  margin-left: 44px;
  margin-right: 44px;
}

.panel-register p {
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
}

.panel-summary {
  margin-bottom: 105px;
}

.panel-text-header {
  margin-top: 8px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--minBlue-color) !important;
}

.earnreward {
  margin-top: 50px;
}

.earnreward img {
  width: 100%;
}

.register-panel img {
  width: 100%;
}

.participate-survey img {
  width: 100%;
}

.create-account-header {
  font-weight: 600;
  font-size: 20px;
  color: var(--blue-color);
}

.create-form {
  border: 1px solid var(--blue-color);
  margin-top: 29px;
  border-radius: 15px;
}

.create-form-heading {
  margin-top: 48px;
  margin-right: 48px;
  margin-left: 64px;
  margin-bottom: 48px;
}

.img-earnreward {
  text-align: center;
  align-items: center;
  width: 255.42px;
  height: 234.8px;
  margin: auto;
}

.img-register-panel {
  margin-top: 85px !important;
  width: 269px;
  height: 250px;
  margin: auto;
}

.img-participate-survey {
  margin-top: 48px !important;
  width: 302px;
  height: 230px;
  margin: auto;
}
.icon-bottum {
  border-bottom: 1px solid !important;
  border-radius: unset !important;
}
/*Privacy-policy page*/
.privacy-page {
  top: 69.5px !important;
  padding-left: 104px;
  padding-right: 104px;
}

.privacy-policy-tabs {
  margin-bottom: 12px;
}

.privacypolicy {
  font-size: 12px;
}

.privacypolicy .form-check-input {
  float: unset !important;
}

.privacy-policybtn {
  margin-top: 20px;
  margin-right: 0px;
}

.privacy-policyfooter .footer {
  background-color: var(--blue-color) !important;
  color: white;
  padding-left: 103px;
  padding-right: 129px;
}

.privacy-policyfooter span {
  color: white !important;
}

.nav {
  border-bottom: none !important;
}

.privacy-page .nav-link {
  padding: 8px !important;
  user-select: none;
}

.active {
  border: none !important;
  border-bottom: 3px solid var(--blue-color) !important;
  background-color: var(--bodyBg-color) !important;
  color: var(--blue-color) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.privacy-heading {
  font-size: 14px;
  font-weight: 700;
  color: var(--blue-color) !important;
  margin-top: 20px;
}

.privacy-top {
  font-weight: 500;
  color: var(--black-color);
  margin-top: 10px !important;
}

.privacy-text {
  margin-top: 12px;
  font-size: 14px;
  padding-bottom: 50px;
}

.terms-box {
  padding-bottom: 30px;
}

/*Contact-us page */

.contact-us {
  margin-top: 30px;
  margin-left: 104px;
  // margin-right: 236px;
  margin-bottom: 84px;
  height: 100%;
}

.selected-files-div {
  padding-left: 20px;
}

.cancel-img {
  position: absolute;
  cursor: pointer;
  margin-left: 50px;
  margin-top: -6px;
}

.cancel-img i {
  background-color: black;
  color: #ffff;
  border-radius: 50%;
}

.cancel-img i:hover {
  background-color: var(--blue-color);
}

.filePhoto {
  width: 70px;
  border: 1px solid var(--grey-dark-color);
  padding: 2px;
  border-radius: 10px;
  margin-left: 2px;
}

.filePhoto img {
  width: 100%;
  border-radius: 6px;
  aspect-ratio: 3/2;
  object-fit: fill;
}

.addtext-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.email {
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  padding: 40px !important;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 35px;
  line-height: 2rem;
}

.form-box {
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  padding: 3px 16px 11px 16px !important;
  border-radius: 5px;
  background: #fff;
}

.contactus-header {
  font-size: 20px;
  font-weight: 600;
  color: var(--blue-color);
}

.contactus-summary {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: var(--grey-dark-color);
}

.contactus-deatils {
  margin-top: 113px;
}

.contactus-deatils p {
  padding-left: 14px;
  font-weight: 400;
  font-size: 14px;
  color: var(--grey-dark-color);
}

.disabled-input {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}

.disabled-redeem-input {
  @extend .disabled-input;
  pointer-events: unset !important;
}

.pointer-none {
  pointer-events: none;
}

// .contact-textgroup {
//     margin-top: 48px;
// }

.cursor-pointer {
  cursor: pointer;
}
.user-text-area {
  background: unset;
  border-radius: 8px !important;
  border: 1px solid var(--light-grey-color);
  height: 20vh;
}
.text-area {
  background: unset;
  // height: 100px;
}

.support-reson {
  background-color: transparent !important;
  color: var(--grey-dark-color);
  font-size: 14px;
}

.support-reson:focus {
  outline: none !important;
  box-shadow: none !important;
}

/*Create Account Page*/

.create-form .required-field {
  color: red;
  margin-left: 4px;
}

.success-password {
  list-style: none;
}

.success-password::before {
  content: "✅";
  margin-right: 5px;
}

.error-password {
  list-style: none;
}

.error-password::before {
  content: "❌";
  margin-right: 5px;
}

.create-form .error-message {
  color: red;
}

.create-form input.ng-touched.ng-invalid {
  border: 1px solid red;
  background: #f8e9e9 !important;
}

// .createaccount-page {
//     margin-top: 20px;
// }

.create-form .dropdown-menu {
  font-size: 12px;
}

.signup-header {
  font-size: 20px;
  color: var(--grey-darkest-color);
  font-weight: 400;
}

// .signup-div input.ng-touched {
//     background-color: #ffdada !important;
//     border: 1px solid red !important;
// }

.signup-text {
  @extend .signup-header;
  color: var(--blue-color);
}

.joinnow-template {
  margin-top: 77px;
}

.joinnow-template-pints {
  margin-top: 40px;
  margin-bottom: 15px;
}

.joinnow-template-footer {
  margin-top: 100px;
  margin-left: 24px;
}

.joint-now {
  // height: calc(100vh - 100px);
  padding: 20px 20px 0px 20px;
  overflow: auto;
}

.password-criteria {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 10;
  top: 36px;
  width: 100%;
  border-radius: 4px;
}

.join-header {
  font-weight: 800;
  font-size: 24px;
  color: (var(--blue-color));
}

.signup-div {
  background-color: (var(--card-bg-color));
  border-radius: 8px;
  padding: 24px;
}

// .signup-data {
//     margin: 24px;
// }

.signup-btn {
  border-radius: 24px;
  font-size: 14px;
}

.popover-body {
  background-color: var(--bodyBg-color);
  color: var(--grey-dark-color);
}

.invalid-input {
  border-bottom: 1px solid red !important;
  background: #eee5e5 !important;
}

.invalid-selection {
  background-color: unset !important;
  margin-bottom: 16px;
}

.invalid-selection select {
  background-color: #eee5e5 !important;
  border-radius: unset;
  border-bottom: 1px solid red !important;
}

.invalid-selection hr {
  display: none;
}

.create-account-signup footer {
  // position: fixed;
  // background-color: var(--blue-color) !important;
  // color: white;
  padding-left: 103px;
  padding-right: 129px;
}

// .form-floating:not(.form-control:disabled)::before {
//     content: unset !important;
// }

.privacy-anchor {
  text-decoration: unset;
}

.privacy-anchor:hover {
  text-decoration: underline;
}
.floating-group {
  padding: 5px !important;
}

.floating-group input {
  background-color: unset !important;
}

.floating-group input:focus {
  outline: unset !important;
  box-shadow: none;
}
.forgot-parent-div button {
  background-color: #0d6efd !important;
  color: #ffff !important;
  outline: unset !important;
  box-shadow: none !important;
}

.floating-group .floating-input {
  border: unset !important;
  // border-bottom: 1px solid !important;
  color: var(--grey-dark-color);
  font-size: 14px !important;
}

.floating-label {
  padding: 27px !important;
  font-size: 14px;
  color: var(--grey-dark-color) !important;
}

.join-page-hr {
  margin-top: 0px;
  width: 100%;
}

.div-formdata {
  margin-top: 24px;
}

.join-select {
  background-color: var(--card-bg-color) !important;
  border: unset;
  outline: unset !important;
  box-shadow: none !important;
  font-size: 14px;
  color: var(--grey-dark-color);
  cursor: pointer;
}

/* body-Page */
.toggaleButton {
  display: none;
}
.custom-alert-toastr {
  position: absolute;
  width: 64%;
  margin-top: 11px;
}
.body-page {
  display: flex;
  flex-direction: row;
  height: 100vh;
  top: 0;
  margin-left: 12px;
  margin-right: 32px;
  transition: all 0.5s ease;
}

.body-page .sidenav {
  width: 23%;
  margin-top: 12px !important;
  height: 100vh;
  position: fixed;
}

.main-body .container-fluid {
  padding-left: unset !important;
  padding-bottom: 29px !important;
  padding-right: unset;
}

.rightside-page {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 26.8%;
}

.active-page {
  padding: 8px 16px 8px 8px;
  background-color: var(--blue-color);
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px;
  color: #ffffff !important;
}

.active-page i {
  color: #ffffff;
}

.active-page:hover {
  padding: 8px 16px 8px 8px;
  background-color: var(--blue-color) !important;
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px;
  color: #ffffff !important;
}

.active-page .nav-label {
  color: #ffffff !important;
}

.logo-landingpage {
  width: 240px;
}

.redirect-pageLog {
  width: 25%;
}

.logo-img {
  width: 70%;
}

.redirectlogin {
  font-weight: 600;
  text-decoration: unset;
}

.forgot-logo-image {
  width: 30%;
}

.nav-forgotpassword {
  -webkit-box-shadow: 5px 5px 5px rgb(238 236 236 / 50%);
  padding: 0px 10px 10px 10px;
  width: 100%;
}

/*Dashboard Page Css*/
.main-sidebar .justify-content-between {
  align-items: center;
}

.sidebar-collapsed {
  width: 20%;
}

.redirect-page {
  height: 100%;
  box-shadow: 0px 0px 5px 0px;
}

.sidebar-div {
  z-index: 1;
  top: 0;
}

.sidebar-div .logo-container {
  margin-left: 16px;
  margin-top: 8px;
}

.sidebar-div .btn-closesidebar {
  margin-left: auto;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.dashboard .dashboard-data {
  width: 100% !important;
}

.profile-div {
  margin: 16px !important;
}

.profile-image {
  width: 70px;
  height: 70px;
  padding: unset;
}

.profile-image img {
  width: 100%;
  border-radius: 50%;
}

.profile-description {
  margin-left: 5px !important;
}

.profile-name {
  font-weight: 700;
  font-size: 16px;
}

.profile-email {
  font-weight: 400;
  font-size: 10px;
  word-break: break-word;
}

.userlevel-heading {
  font-weight: 700;
  font-size: 14px;
  color: var(--blue-color);
}

.user-level {
  background-color: #83a4ef;
  border-radius: 4px;
  color: black;
  font-weight: 600;
  font-size: 12px;
  width: 80px;
  text-align: center;
  padding: 3px;
  margin-left: 16px;
  box-shadow: 0px 1px 2px 1px #e9e6e6;
}

.profile-level-status {
  align-items: center;
  padding-left: 18px;
  align-items: baseline !important;
}

.profilepoint-status {
  margin-top: 24px;
}

.profilepoint-status {
  font-weight: 400;
  font-size: 16px;
  color: var(--grey-darkest-color);
}

.profile-point {
  font-weight: 700;
  font-size: 16px;
  color: var(--blue-color);
}

.sidebar-div {
  border: 1px solid var(--grey-dark-color);
  border-radius: 16px;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

.sticky-top {
  background-color: var(--bodyBg-color) !important;
}

.dashboard-heading h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--blue-color);
}

.dashboardhr-line {
  width: 360px !important;
  color: var(--blue-color);
  height: 3px !important;
  border-bottom: 3px solid var(--blue-color);
}

.redeem-dashboardhr-line {
  @extend .dashboardhr-line;
  width: inherit !important;
}

.dashboard-mainboady {
  margin-top: 18px;
}

.dashboard-cards {
  margin-top: 24px;
  margin-bottom: 48px;
  margin-left: 0px !important;
}

.dashboard-cards .card:hover {
  background-color: var(--card-hover-color);
  cursor: pointer;
  transform: scale(1);
}

.card {
  margin-bottom: 24px;
  align-items: center !important;
  padding: 16px 20px !important;
  background-color: var(--card-bg-color);
  border-radius: 8px;
}

.card .card-text {
  font-weight: 600;
  font-size: 32px;
  color: var(--blue-color);
}

.card small {
  font-weight: 400;
  font-size: 14px;
  color: var(--grey-darkest-color);
  white-space: nowrap;
}

.dashboardbody h3 {
  font-weight: 600;
  font-size: 20px;
  color: var(--blue-color);
}

.table-heading th {
  color: var(--blue-color);
  font-weight: 500;
  font-size: 16px;
  border-bottom: 2px solid var(--blue-color) !important;
}

.dashboard-table {
  margin-top: 16px;
  margin-left: 0px;
}

.dashboard-table td {
  font-size: 14px;
}

// .dashboard-table table {
//     margin-bottom: 50px !important;
// }

.dashboard-table hr {
  color: var(--blue-2-color);
  height: 3px;
}

.dashboard-table .tooltip {
  margin: 0px -40px !important;
}

.show-all-survey a {
  float: right;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  color: var(--blue-color);
}

.sidebar {
  margin-top: 24px;
  margin-bottom: 32px;
  padding-bottom: 24px;
  margin-bottom: 20px;
}

.table-btn {
  align-items: center;
  padding: 0px 16px;
  text-decoration: none;
  height: 28px;
}

.sidebar-menu {
  padding-left: 0px;
}

.sidebar-menu li {
  list-style: none;
  padding: 8px 16px 8px 8px;
  align-items: center;
}

.sidebar-menu li:hover {
  background-color: var(--grey-medium-color);
  border-radius: 8px;
}

.sidebar-menu a {
  cursor: pointer;
  text-decoration: none;
}

.sidebar-menu .nav-label {
  font-weight: 400 !important;
  font-size: 16px;
  color: var(--black-color);
}

/*  Fixed-items manu*/
.fixed-topheader {
  position: fixed;
  width: -webkit-fill-available;
  top: 0;
  z-index: 1 !important;
}

.fixed-body {
  margin-top: 100px !important;
  // position: relative;
}

.fixed-topsidenav {
  background-color: var(--bodyBg-color);
  position: fixed;
  width: 22.5%;
  z-index: 1200;
  padding: 2px;
  border-radius: 20px;
}

.fixed-sidenav-body {
  margin-top: 13rem;
}

.ml-3 {
  margin-left: 16px;
}

/* My Surveys Page*/
.ml-6 {
  margin-left: 44px;
  margin-right: 32px;
}

.point-container {
  align-items: center;
}

.point {
  font-weight: 400;
  font-size: 16px;
  color: var(--grey-darkest-color);
}

.point-balance {
  font-weight: 600;
  font-size: 24px;
  margin-left: 24px;
  color: var(--blue-color);
}

.card-button {
  align-items: center;
  padding: 2px 16px;
  background-color: var(--blue-color) !important;
  font-size: 14px;
  width: 90px;
}

.dash-card-button {
  @extend .card-button;
  width: unset;
  padding: 3px 30px;
}

.card-survey {
  margin-top: 48px;
}

.survey-code {
  color: var(--blue-color);
  font-weight: 500 !important;
  margin-top: 3px;
  margin-bottom: 4px;
}

.card-survey .card {
  height: 172px;
  padding: 16px;
  align-items: unset !important;
  background: linear-gradient(
    289.65deg,
    #a0c6fd -23.63%,
    rgba(230, 239, 253, 0.37) 100%
  );
  box-shadow: 0px 0px 2px 1px #b6b6e8;
}

.page-container {
  position: relative;
  width: 100%;
  height: 80vh;
}

.blurred {
  filter: blur(3px);
  -webkit-filter: blur(5px);
  pointer-events: none; /* Prevent interaction with the content */
}

.overlay {
  /* Fixed position to cover the entire viewport */
  position: absolute;
  top: 28%;
  left: 50%;
  background: rgba(114, 114, 114, 0.21);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  transform: translate(-50%, -50%);
  border: 1px solid #aeaeae;
  border-radius: 30px;
  box-shadow: 0px 0px 2px 1px #aaaaaa;
}

.btn-survey {
  background-color: #a9c3e4 !important;
}

.overlay .verifymessage-box {
  background-color: unset !important;
  color: #484848;
  font-size: 16px;
}

.overlay.visible {
  opacity: 1;
  pointer-events: auto; /* Allow interaction with the overlay */
}

.disabled {
  opacity: 0.5;
}

.card-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.card-survey .survey-minut {
  font-weight: 400;
  font-size: 12px;
  margin-left: 7px;
  color: var(--blue-color);
}

.card-survey .card-text {
  margin-left: 4px;
  font-weight: 700;
  font-size: 24px;
}

.card-survey .justify-content-between {
  text-align: center;
}

.center-div {
  // height: calc(90vh - 50px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  overflow: hidden;
  padding-top: 10%;
  margin: auto !important;
}

.disabled a {
  background-color: #cad3d0 !important;
  color: #585858 !important;
  pointer-events: none !important;
  filter: opacity(0.6);
}

.show-mobile {
  filter: opacity(0.4);
}

.survey-time {
  white-space: nowrap;
}

.survey-btn-align {
  justify-content: end;
}

// .modal-backdrop.show{
//     opacity: unset !important;
// }
/*Personal Details Page */

.password-mismatch {
  @extend .password-criteria;
  position: relative;
  top: 1px;
}

.personal-detailtab {
  margin-top: 10px;
  margin-left: 1px;
  padding-bottom: 15px;
}

.w9form-file {
  color: var(--yellow-color);
}

.w9Forms-btn {
  display: flex;
}

.red-asterisk-list {
  list-style-type: none;
}

.personal-detail-nav {
  flex-wrap: nowrap;
  .mySurvey-tab {
    // width: 125px;
    text-align: center;
  }
}

.viewMoreSurveyBtn {
  font-size: 14px;
  color: var(--blue-color);
  cursor: pointer;
  text-align: end;
}

.viewMoreSurveyBtn:hover {
  text-decoration: underline;
}

// .personal-detail-nav {
//     position: fixed;
//     background: var(--bodyBg-color);
//     z-index: 1;
// }
.verifyEmail {
  border: 1px solid;
  margin-left: 8px;
  padding: 0px;
  height: 20px;
  // width: 85px;
}

.verified-email {
  font-weight: 600;
}

.unverified-email {
  color: var(--grey-dark-color);
  box-shadow: 0px 7px 13px 0px #e3dddd !important;
  background: #ffff;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 1px;
  padding: 48px;
  width: 100%;
}

.unverified-email .message-bottum {
  margin-top: 12px;
  // font-size: 12px !important;
  text-align: center;
}

.unverified-email .input-group-text {
  border: 1px solid #dee2e6 !important;
  border-left: 0 !important;
}

.personaldetails {
  color: white;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  padding: 8px 16px;
  margin-left: 9px;
  background-color: var(--blue-color);
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.txt-group input:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.txt-group textarea:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

select:disabled {
  cursor: not-allowed;
}
.emailp-5 .txt-group {
  padding: 5px !important;
}
.user-profile {
  font-weight: 600;
  font-size: 16px;
  color: var(--blue-color);
  align-items: center;
  padding: 8px 16px;
  margin-left: 9px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid var(--blue-2-color);
}

.user-profile:hover {
  background-color: var(--blue-3-color);
}

.profileimg-header {
  font-weight: 600;
  font-size: 24px;
  color: var(--blue-color);
  margin-top: 48px;
  margin-bottom: 48px;
}

.profile-select {
  display: none;
  width: 10px;
}

.txtprofile-select {
  position: unset !important;
}

.delete-account .modal.fade .modal-dialog {
  max-width: 550px !important;
}

#floatingTextarea2:focus {
  outline: unset !important;
  box-shadow: none;
}

#accountDeleteCheck:focus {
  outline: unset !important;
  box-shadow: none;
}

.textAreaBox {
  border: 1px solid red;
  border-radius: 4px;
}

.select-profile-photo img {
  width: 120px;
  height: 120px;
  margin-left: 12px;
  border-radius: 50%;
}

.select-profile-photo i {
  position: absolute;
  margin-top: 90px !important;
  margin-left: -76px;
  background-color: white;
  width: 24px;
  height: 22px;
  border-radius: 5px;
  text-align: center;
  color: var(--blue-color);
  cursor: pointer;
}

.or-divider {
  margin-top: 32px;
  font-weight: 600;
  font-size: 14px;
  color: var(--black-color);
  padding: 0px 56px;
}

.choose-avatar {
  font-weight: 600;
  font-size: 14px;
  color: var(--blue-color);
  padding: 10px;
  margin-top: 24px;
}

.avatar-list {
  margin-top: 16px;
}

.avatar-list div {
  border-radius: 50%;
  margin-left: 16px;
  background-color: var(--blue-3-color);
  width: 65px;
  height: 65px;
  margin-bottom: 20px;
}

.txt-group {
  padding: 8px;
}

.txt-group:hover {
  background-color: var(--blue-3-color);
  border-radius: 8px;
  cursor: pointer;
}

.txt-group input {
  background-color: unset !important;
}

.txt-group input:focus {
  outline: unset !important;
  box-shadow: none;
}
.txt-group textarea:focus {
  outline: unset !important;
  box-shadow: none;
}

.txt-group input:hover {
  background-color: unset !important;
}

.avtar-img {
  width: 65px;
  border-radius: 50%;
  cursor: pointer;
}

.avtar-img:hover {
  transform: scale(1.2);
}

/*  point details page */
.showpop {
  background-color: var(--card-bg-color);
  position: absolute;
  top: 240px;
  margin-left: 0px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  width: 16.5%;
  color: var(--grey-darkest-color);
}

.showpop div {
  padding: 6px;
  padding-left: 12px;
  font-size: 14px;
  cursor: pointer;
}

.showpop div:hover {
  background-color: var(--card-hover-color);
  border-radius: 4px;
}

.point-detail {
  margin-top: 48px;
}

.point-detail .mb-4 {
  margin-left: 24px;
  margin: 0px;
}

.default-tab {
  align-items: center;
  border-radius: 10px;
  justify-content: space-between;
  cursor: pointer;
}

.default-tab-span {
  padding-left: 16px;
  font-weight: 700;
  font-size: 16px;
  color: var(--blue-color);
}

.default-tab-anchor {
  color: var(--blue-color) !important;
}

.default-tab-anchor:hover {
  background-color: var(--card-bg-color) !important;
  color: var(--blue-color) !important;
}

.point-detail .btn-surveypoint {
  border: 1px solid var(--blue-color);
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none !important;
  // width: 190px;
  text-align: center;
}
.default_pointbtn {
  cursor: pointer;
  text-decoration: none !important;
  // padding: 0px 0px 0px 20px !important;
  font-weight: 400;
  color: var(--blue-2-color) !important;
  margin: auto !important;
}
.default_point {
  font-weight: 700;
  color: var(--blue-2-color) !important;
  border-right: 2px solid var(--blue-2-color) !important;
}
.active_point {
  background-color: var(--blue-color) !important;
  color: #ffff !important;
  font-weight: 700;
}
.active_pointbtn {
  color: var(--blue-color) !important;
  cursor: pointer;
  text-decoration: none !important;
  //    padding: 0px 0px 0px 20px !important;
  font-weight: 400;
  margin: auto !important;
}
.s-point {
  align-items: center;
  border-radius: 10px;
  justify-content: space-between;
  border: 2px solid var(--blue-color);
  cursor: pointer;
}

.s-point-span {
  padding-left: 16px;
  font-weight: 700;
  font-size: 16px;
  color: var(--blue-color);
}

.s-point-anchor {
  background-color: var(--blue-color);
  color: white !important;
  text-decoration: none !important;
}

/* profile-question-page */

.questionImg {
  width: 30px;
}

.profile-questiondiv {
  margin-top: 24px;
  margin-bottom: 19px;
}

.profile-question-tabs .first-nav {
  margin-left: unset !important;
  cursor: pointer;
  margin-right: 10px;
  padding: 2px 4px 3px 3px !important;
  border-radius: 5px;
}

.selected-tab {
  background-color: var(--blue-color);
  color: #ffff;
  font-weight: 600;
  margin-left: unset !important;
  cursor: pointer;
  margin-right: 10px;
  padding: 2px 4px 3px 3px !important;
  border-radius: 5px;
}

.first-nav:hover {
  background-color: var(--card-bg-color);
}

.first-nav {
  border: 1px solid var(--blue-color);
}

.questionTitle {
  padding: 8px;
  border-radius: 5px;
}

// .floating-button {
//     position: fixed;
//     bottom: 20px;
//     right: 20px;
//     color: #ffffff;
//     width: 56px;
//     height: 56px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;
//   }

// .profile-question-tabs .nav-item {
//     margin-left: 8px;
//     margin-top: 9px;
// }

.profile-question-tabs .nav-link {
  // margin-left: 11px;
  align-items: center;
  padding: 5px 12px;
  height: 32px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid var(--blue-color);
  border-radius: 4px;
}

.profile-question-tabs .nav-link:hover {
  background-color: var(--blue-3-color);
}

.profile-question-content {
  margin-top: 24px;
  margin-right: 32px;
}

.selected-question-tab {
  background-color: var(--blue-color);
  color: #ffffff;
}

.selected-question-tab:hover {
  background-color: unset !important;
}

.questions {
  margin-left: 12px;
  // margin-top: 15px;
}

.active-question {
  background-color: var(--blue-color);
  border-radius: 4px;
}

.active-question a {
  color: #ffffff !important;
}

.active-question a:hover {
  background: unset !important;
  color: #ffffff !important;
}

.profile-question-content .accordion-button {
  box-shadow: unset !important;
  font-size: 14px;
  font-weight: 400;
  color: var(--grey-darkest-color);
  height: 21px !important;
  margin-top: 16px !important;
  background-color: var(--bodyBg-color);
}

.profile-question-content .accordion-button:hover {
  color: var(--blue-color) !important;
}

.profile-question-content .accordion-button:not(.collapsed) {
  box-shadow: unset !important;
  outline: unset !important;
  background-color: var(--blue-3-color);
  color: var(--blue-color) !important;
  z-index: 0;
}

.profile-question-content .accordion-item {
  background-color: var(--bodyBg-color);
}

.profile-question-content .form-check label {
  font-weight: 400;
  font-size: 12px;
}

.profile-question-content .form-check label:hover {
  color: var(--blue-color) !important;
  cursor: pointer;
}

.form-check-input:checked + .form-check-label {
  color: var(--blue-color) !important;
}

// .accordion-button::after {
//     background-image: unset !important;
// }

.accordion-button:not(.collapsed)::after {
  background-image: url("../assets/image/close dropdown.png") !important;
}

/*Question-Sub-page --- automobile*/
.questionsubmit-btn {
  background-color: var(--blue-color) !important;
  // margin-right: 34px;
  margin-top: 30px;
  // height: 35px;
  font-weight: 400 !important;
  width: 116px;
  // width: 75px;
  height: auto;
  position: fixed;
  bottom: 40px;
  right: 100px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionsubmit-btn:disabled {
  border: unset;
}

.colum-count {
  column-count: 3;
  margin-top: 15px;
}

.automobile .container {
  word-break: break-all;
}
/*Redeem-point page*/

.redeem-point {
  margin-top: 0px !important;
}

.points-info-modal {
  max-width: 60% !important;
}

.redeem-calc-text {
  font-size: 10px !important;
}

.cardAmount {
  box-shadow: 0px 0px 4px 0px;
  border: 2px solid red !important;
}
.avatar {
  border: 2px solid var(--blue-color) !important;
  box-shadow: 0px 0px 2px 1px var(--blue-color);
}
.redeem-point .vr {
  // height: 141px;
  border: 1px solid #ffffff;
}

.redeem-header {
  font-weight: 600;
  font-size: 20px;
  color: var(--blue-color);
  border-bottom: 2.5px solid var(--blue-color);
  width: 124px;
}

.redeems-header {
  font-weight: 600;
  font-size: 14px;
  color: #ffff;
  margin-bottom: 10px;
}

.r-total {
  font-weight: 400;
  font-size: 14px;
  color: var(--grey-darkest-color);
}

.r-point {
  font-weight: 700;
  font-size: 14px;
  color: var(--blue-color);
}

.available-amount {
  height: 66px;
  padding: 8px;
  background-color: var(--blue-color);
  border-radius: 8px;
}

.available-amount .amount {
  font-weight: 400;
  font-size: 14px;
  color: var(--grey-medium-color);
}

.available-amount .t-amount {
  font-weight: 700;
  font-size: 14px;
  color: var(--grey-medium-color);
}

.pl-5 {
  padding: 8px 16px;
}

.redeem-options {
  box-shadow: 5px 5px 13px 0px #e2e2e2;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 4px;
  margin-bottom: 10px;
  height: min-content !important;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(146px, 1fr)
  ); /* Adjust the minmax value as needed */
  gap: 1rem; /* Adjust the gap value as needed */
}

.redeem-card {
  padding: 8px !important;
  cursor: pointer !important;
  border: 1px solid var(--minBlue-color);
  height: 100%;
}
.tango-enable {
  .redeem-options {
    border: unset !important;
  }
 }
.redeemBtn-info .redeem-info {
  position: relative;
  width: auto;
  left: 123px;
  top: -30px;
}
.paypla-selected-image {
  height: 111px;
  border-radius: 8px;
  object-fit: cover;
}
.cursor-unset {
  cursor: unset !important;
}

.redeem-card .card-image {
  width: -webkit-fill-available;
  // height: 105px;
  background-color: var(--blue-3-color);
  border-radius: 8px !important;
  object-fit: cover;
}

.redeem-card .card-footer {
  background: unset;
  border-top: unset;
  // font-weight: 700;
  font-size: 12px;
  color: var(--blue-color);
  margin-top: auto;
  padding: unset !important;
}

.redeem-note {
  font-size: 11px !important;
}

.show-catelog {
  height: 390px;
  overflow: auto;
  margin-bottom: 20px;
  padding-top: 16px;
}

.show-catelog .redeem-card {
  cursor: default !important;
  padding: 4px !important;
}

.show-catelog-footer {
  font-size: 8px !important;
  word-break: keep-all;
  text-align: center;
}

.redeem-card .form-select {
  background-color: var(--card-bg-color);
  border: unset;
  cursor: pointer;
}

.selected-card {
  cursor: pointer;
  padding-left: 8px !important;
  padding-right: 8px !important;
  background-color: var(--blue-color);
}

.selected-card-image {
  width: 100%;
  height: 105px;
  background-color: var(--blue-3-color) !important;
  border-radius: 8px !important;
}

.selected-card-footer {
  background: unset;
  border-top: unset;
  color: #ffffff !important;
}

.notselected-card {
  border-radius: 8px;
  // background: linear-gradient(289.65deg, #FF7531 -23.63%, #FF8D31 100%) !important;
  border: unset;
  font-size: 13px;
  font-weight: 600;
  background-color: unset !important;
  padding: unset !important;
  position: absolute;
  top: 49px;
  right: 12px;
  left: 10px;
}

.disabled-card {
  pointer-events: none;
  filter: opacity(0.5);
  cursor: no-drop !important;
}

.reward-selection .card-center {
  margin: auto;
}

.reward-selection button {
  margin-top: 10px !important;
  border-radius: 10px !important;
  margin: auto;
}
.cardvalue {
  border: 1px solid;
  border-radius: 5px;
  padding: 6px;
  width: fit-content;
}
.cardvalue h6 {
  font-size: 11px !important;
  margin-bottom: 0px !important;
}
.rd-user-level {
  border: 2px solid var(--blue-color);
  padding: 2px;
  border-radius: 6px;
}

.rd-validpassowrd-hed {
  padding: 8px;
  border-bottom: 1px solid;
  font-size: 14px;
}

.points-summary {
  margin-left: 4rem;
}

// .rd-enterpassowrd-div{

// }
.redeem-point {
  margin-top: 32px;
  position: relative;
  animation: movePage 0.5s 1;
}
@keyframes movePage {
  from {
    left: 500px;
  }

  to {
    left: 0px;
  }
}
.level-info {
  border-top: 3px solid #bed3ff;
  border-width: 3.4px;
}

.table-scroll {
  height: 300px;
  overflow-y: auto;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead th {
    position: sticky;
    top: 0;
    background-color: var(--bodyBg-color) !important;
    border-bottom: 2px solid var(--blue-color);
  }
}

.page-selection-bg {
  background-color: var(--bodyBg-color) !important;
  border-radius: 6px;
  border: 1px solid var(--light-grey-color);
}
.table-scroll::-webkit-scrollbar {
    width: 3px;
    height: 8px;
    background: transparent;
  }

.custom-pagination {
  display: flex;
  justify-content: end;
  .pagination {
    margin-bottom: unset !important;
  }
  .page-item {
    margin: 0 5px;

    .page-link {
      color: var(--blue-color);
      background-color: transparent;
      border: 1px solid #dee2e6;
      padding: 1px 6px;
      border-radius: 4px;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: var(--blue-color);
        color: #ffff;
      }

      &:focus {
        box-shadow: unset !important;
        outline: unset !important;
      }
    }

    &.active .page-link {
      background-color: #007bff;
      color: #fff;
      border-color: #007bff;
    }

    &.disabled .page-link {
      color: #6c757d;
      pointer-events: none;
      background-color: #e9ecef;
    }
  }
  .active {
    border-bottom: unset !important;
  }
}

.user-level-hed {
  text-align: center;
  transform: translateY(-45%);
  background-color: var(--bodyBg-color);
  color: var(--grey-darkest-color);
  width: 150px;
  margin: auto;
}

.user-level-hed img {
  width: 12%;
}

.rd-level-card {
  @extend .card;
  padding: 8px 0px !important;
  background-image: linear-gradient(to right, #83a4ef, #e4e4e4) !important;
  box-shadow: 0px 1px 2px 0px;
  border: unset;
  margin-bottom: 24px;
  height: 100%;
  align-items: start !important;
}

.rd-levels {
  background-color: #ffff;
}

.redeem-level-info {
  padding-left: 20px;
}

.currentUserLevel {
  border: 2px solid var(--blue-color);
  // filter: drop-shadow(0px 0px 2px var(--grey-medium-color));
}

.validation-div {
  border: 1px solid #e5c100 !important;
  border-radius: 16px !important;
  // display: grid;
  // place-items: center;
}

.rd-level-img {
  width: 100px;
}

.revalidation-password {
  background-color: unset !important;
  border: unset !important;
  border-bottom: 1px solid !important;
  border-radius: unset !important;
  color: var(--grey-dark-color);
  font-size: 14px;
}

.rules-hed {
  font-size: 14px;
  font-weight: 700;
  color: var(--grey-dark-color);
}

.redeemverify-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.rd-list-group {
  padding-left: 40px;
}

.rd-list-group li {
  margin-bottom: 5px;
}
/*Refer & Earn Page*/

.total-rpoint span {
  font-size: 20px;
  font-weight: 500;
  color: var(--blue-color);
}

.total-rpoint .total-rpoint-span {
  margin-left: 24px;
  font-size: 20px;
  font-weight: 700;
}

// User-privacy-Page

.user-terms-page .privacy-page {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.user-contact-us {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  // background: #ffff;
  background: var(--bodyBg-color);
  border-radius: 6px !important;
  padding: 16px !important;
  margin-top: 16px;
}
.user-contact-us .email {
  padding: 18px !important;
  background-color: unset !important;
  box-shadow: unset !important;
  border: 1px solid var(--light-grey-color) !important;
}

.error-redeemDailog {
  filter: blur(5px);
  position: relative;
  pointer-events: none;
}

.div-error-redeemDailog {
  position: absolute;
  top: 18rem;
}

.div-error-redeemDailog dialog {
  border: 1px solid var(--light-grey-color);
  border-radius: 4px;
  background-color: var(--bodyBg-color);
  padding: 25px;
  box-shadow: 0px 0px 1px 0px #2d2c2c;
}

.redemption-rule .rules {
  font-size: 11px;
  text-align: justify;
}

.redemption-rule-card .tooltip-inner {
  text-align: justify;
}

.redemption-rule-card .tooltip {
  --bs-tooltip-max-width: unset !important;
  width: 280px !important;
  line-height: 1.4 !important;
}

.user-level-tophed {
  position: relative;
  top: -24px;
  z-index: 1;
}

.level-box-container {
  margin-top: -24px;
}

.i-icon {
  padding: 1px;
  background-color: var(--blue-3-color);
  background-color: var(--blue-3-color);
  border-radius: 50%;
  box-shadow: 0px 1px 3px 0px;
}

.i-icon span {
  padding: 5px;
  font-weight: 700;
  font-family: monospace;
  color: var(--blue-color);
  cursor: pointer;
}

.points-info-i {
  @extend .i-icon;
  // background-color: var(--blue-color) !important;
  // span {
  //     color: white !important;
  // }
}

#appOpenImg {
  width: 150px;
  mix-blend-mode: multiply;
}

.appOpenHeading {
  font-size: 1.2em;
  font-weight: 800;
  color: rgb(26, 26, 26);
}

.appOpenDescription {
  text-align: center;
  font-size: 0.8em;
  font-weight: 600;
  color: rgb(99, 99, 99);
}

.buttonContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.acceptButton {
  width: 80px;
  height: 30px;
  background-color: var(--blue-color);
  transition-duration: 0.2s;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px #977ef3, 0 2px 4px -1px #977ef3;
  transition: all 0.6s ease;
}

.declineButton {
  width: 80px;
  height: 30px;
  background-color: #dadada;
  transition-duration: 0.2s;
  color: rgb(46, 46, 46);
  border: none;
  cursor: not-allowed;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px #bebdbd, 0 2px 4px -1px #bebdbd;
  transition: all 0.6s ease;
}

.declineButton:hover {
  background-color: #ebebeb;
  box-shadow: 0 10px 15px -3px #bebdbd, 0 4px 6px -2px #bebdbd;
  transition-duration: 0.2s;
}

.acceptButton:hover {
  background-color: #544ae0;
  box-shadow: 0 10px 15px -3px #977ef3, 0 4px 6px -2px #977ef3;
  transition-duration: 0.2s;
}

// FAQ'S-Page
.faqs-hed h1 {
  color: var(--blue-color);
  font-weight: 900;
}

.faqs-hed p {
  color: var(--grey-dark-color);
  font-size: 16px;
  margin-bottom: 2px;
}

.faqs-hed hr {
  width: 14%;
  display: inline-block;
  margin-top: 8px !important;
  border-top: 2px solid #868686;
}

.faqs-box {
  .accordion-button:not(.collapsed) {
    box-shadow: inset 0 calc(-1 * 1px) 0 #dee2e6 !important;
  }
  .accordion-button {
    background-color: #f4f9ff !important;
    font-weight: 600;
  }
  .accordion-body {
    color: var(--grey-dark-color);
  }
}
.faqs-box .accordion-button:focus {
  outline: unset !important;
  box-shadow: none;
}

.faqs-box .accordion-item {
  background-color: unset !important;
  padding: 8px;
  margin: 8px;
  border-top: 1px solid #dee2e6 !important;
}
.fasq-tabs li {
  user-select: none;
}

.faqs-box .accordion-button::after {
  font-size: 24px;
  content: "+" !important;
  background-image: unset !important;
}

.faqs-box .accordion-button:not(.collapsed)::after {
  content: "-" !important;
  font-size: 24px;
}

.faqs-box .nav {
  border-bottom: 1px solid var(--grey-medium-color) !important;
  width: auto;
}

.faqs-box .accordion-body {
  font-size: 14px;
}

.getApp-toast {
  position: absolute;
  z-index: 1;
  top: 0px;
  width: 100%;
  left: 0px;
}
.getApp-toast .toast {
  width: auto;
  padding: 10px;
  margin: 7px;
}
.getApp-toast .btn-close {
  width: 10px !important;
  position: relative;
  top: -13px;
  left: -2px;
}

.getApp-toast .toast-header {
  border-bottom: unset !important;
}
/*leaderboard-page*/

.leader-detail {
  text-align: center;
  margin-bottom: 55px;
}

.leader-flag {
  width: 100px;
  border-radius: 50%;
  border: 3px solid white;
  height: 70%;
}

.leader-name {
  font-weight: 400;
  font-size: 14px;
}

.leaderboard .leader-primary {
  font-weight: 700;
  font-size: 14px;
  color: var(--blue-color);
  margin: auto;
}

.leaderboard-summary {
  margin-top: 25px;
}

.leader-sumarycard {
  align-items: unset !important;
  background-color: var(--blue-3-color);
}

.leader-sumarycard .leader-flag {
  height: unset !important;
}

.leader-sumarycard .leader-primary2 {
  font-weight: 700;
  font-size: 20px;
  color: var(--blue-color);
  padding: 18px 24px;
  text-align: center;
}
.referwithemail .ng-invalid.ng-touched {
  border: 1px solid red !important;
}

.error {
  border: 1px solid red;
}
/*scrollbar Design*/

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--bodyBg-color);
}

::-webkit-scrollbar-thumb {
  background: var(--grey-dark-color);
  visibility: visible;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--minBlue-color);
}

.body-page .sidebar-div::-webkit-scrollbar {
  width: 1px !important;
}

.body-page .sidebar-div::-webkit-scrollbar-thumb {
  visibility: hidden !important;
}

// Loader
.loader span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--blue-color);
  margin: 0 10px;
}

.loader span:nth-child(1) {
  animation: 1s jump infinite ease-in-out;
}

.loader span:nth-child(2) {
  animation: 1s 0.333s jump infinite ease-in-out;
}

.loader span:nth-child(3) {
  animation: 1s 0.667s jump infinite ease-in-out;
}

.server-img {
  width: 290px !important;
}
@keyframes jump {
  0% {
    transform: translateY(0) scale(1);
  }

  30% {
    transform: translateY(-20px) scale(1.2);
  }

  75% {
    transform: translateY(0) scale(1);
  }
}

.profile-questiondiv .loader {
  margin-top: 30px;
}

.tango-img {
  width: 100%;
  height: 127px;
  object-fit: fill;
}

.reward-paypal-disable {
  filter: opacity(0.67);
  cursor: unset !important;
  .redeem-card {
    cursor: unset !important;
  }
}
.reward-paypal-rd {
  height: 114px;
  object-fit: contain !important;
  padding: 10px;
  mix-blend-mode: multiply;
  background-color: unset !important;
  border: 1px solid #57729d59;
}

.reward-img-paypal {
  @extend .tango-img;
  height: 127px;
  object-fit: contain;
  padding: 18px;
  border-radius: 11px;
}
.reward-img__wrap {
  position: relative;
  mix-blend-mode: multiply;
}

.reward-img__wrap:hover {
  transition: all .2s ease-in;
  transform: scale(1.07);
}

.virtual-mastercard-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.virtual-mastercard {
  background: linear-gradient(135deg, #ff5e62, #ff9966);
  width: 65%;
  max-width: 350px;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-family: 'Arial', sans-serif;

  &:hover{
    transition: all .2s ease-in;
    transform: scale(1.07);
  }
}
.w-65 {
  width: 65% !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  .logo {
    font-size: 14px !important;
    font-weight: bold;
  }
}

.card-footer {
  .reward-box {
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
  }
}


.vm-reward {
  width: fit-content;
  height: fit-content;
  position: relative;
  left: 92px; 
  color: white;
  filter: opacity(0.8);
  font-weight: 700;
  top: -40%;
  font-size: 17px
}

.vm-reward-box {
  @extend .vm-reward;
  left: 50px;
  top: -47%;
}

.reward-flag {
  width: fit-content;
  position: relative;
  top: -3rem;
  left: 11.4rem;
  bottom: 0;
  div {
    font-size: 12px;
    color: #f3f3f3a8;
  }
  &:hover {
    transition: all .2s ease-in;
    transform: scale(1.07);
  }
}

.reward-flag-login {
  @extend .reward-flag;
  left: 11.5rem !important;
}

/*Media Queries*/

@media screen and (max-width: 600px) {
  .reward-flag {
    left: 13.7rem;
  }
  .vm-reward {
    left: 86px;
  }
  .vm-reward-box {
    left: 92px;
  }
  .getApp-toast .toast {
    width: auto;
    padding: 10px;
    margin: 7px;
  }

  .getApp-toast img {
    width: 10%;
  }
  .contact-us {
    margin-top: 0px;
    margin-left: 0px;
  }

  .landingpage-data {
    margin-top: 0px;
  }

  .navbar-nav .nav-link:hover {
    transition: unset;
    transform: unset;
  }

  .point-detail .mb-4 {
    margin: unset;
  }

  .showpop {
    position: relative;
    width: 100%;
    top: -12px;
  }

  .landingpage-data {
    margin-left: 0px;
    margin-right: 0px;
  }

  .landingpage-login {
    width: 401px;
  }

  // .fixed-body {
  //     margin-top: 130px !important;
  // }
  .custom-alert-toastr {
    // width: 100%;
    margin-top: unset;
    width: fit-content;
  }

  .redeemBtn-info .redeem-info {
    left: auto;
    right: 140px;
  }

  .w-auto {
    width: 100% !important;
  }

  .redeem-point {
    margin: auto !important;
  }

  .card-container {
    grid-template-columns: repeat(
      auto-fill,
      minmax(145px, 1fr)
    ); /* Adjust the minmax value as needed */
  }

  .notselected-card {
    top: 90px;
  }

  .user-level-tophed {
    margin-top: 26px !important;
    position: unset;
    top: unset;
    z-index: unset;
  }

  .level-box-container {
    margin-top: 10px;
  }

  .faqs-box .nav {
    display: flex;
    justify-content: center;
  }

  .fixed-topheader {
    width: 100% !important;
  }
}

@media only screen and (min-width: 500px) and (max-width: 650px) {
  .profile-div {
    // margin: 12px !important;
    width: 100%;
    background-color: var(--bodyBg-color);
  }

  .fixed-sidenav-body {
    margin-top: 19rem !important;
    margin-left: 0px !important;
    white-space: nowrap;
  }

  // .body-page .sidenav {
  //     width: 26%;
  // }

  // .body-page .rightside-page {
  //     width: 100%;
  //     margin-left: 43%;
  // }

  .sticky-top {
    position: unset;
  }

  // .fixed-body {
  //     margin-top: 125px
  // }

  .sidebar-div {
    height: 100%;
  }

  .user-level {
    width: unset;
  }

  .profile-description {
    padding: unset;
  }

  .contact-us {
    margin-right: 0px !important;
    margin-left: 15px !important;
  }

  // .active-page {
  //     width: fit-content;
  // }
}

@media only screen and (min-width: 350px) and (max-width: 450) {
}

@media only screen and (min-width: 360px) and (max-width: 1200px) {
  .reward-paypal-rd {
    height: 98px;
  }
  .body-page .sidenav {
    display: none !important;
  }

  .rightside-page {
    margin-left: unset;
    width: 100%;
    padding: 24px;
  }

  .toggaleButton {
    display: unset;
  }

  .body-page {
    margin-left: unset;
    margin-right: unset;
    transition: unset;
  }

  .fixed-topsidenav {
    position: unset;
    width: unset;
    border-radius: unset;
  }

  .fixed-sidenav-body {
    margin-top: unset !important;
  }

  .sidebar-div {
    border: unset;
  }

  .survey-btn-align {
    justify-content: center;
  }

  .profile-div {
    margin: 6px !important;
  }

  .country-status {
    padding-right: 25px;
  }

  .privacy-page {
    padding-left: 12px;
    padding-right: 12px;
  }

  .offcanvas-close {
    display: unset;
  }

  .personal-detail-nav {
    position: unset;
    background-color: unset;
    z-index: unset;
  }

  .personaldetails {
    font-size: 12px;
    margin-left: 10px;
    padding: 8px;
  }

  .user-profile {
    font-size: 12px;
    margin-left: 10px;
    padding: 8px;
  }

  .w9Forms-btn .md-font {
    font-size: 12px;
  }

  .sidebar-menu {
    margin-left: 7px;
  }

  .questions {
    // margin-left: 15px;
    margin-top: unset;
  }

  .profile-question-content .accordion-button:not(.collapsed) {
    background-color: unset !important;
  }

  .profile-question-content .form-check label {
    font-size: 12px !important;
  }
  .automobile .container {
    word-break: unset !important;
  }
  .colum-count {
    column-count: unset !important;
  }
  .profile-question-content {
    margin-right: unset !important;
  }
  .questionsubmit-btn {
    // right: 20px !important;
  }

  .delete-account-btn {
    width: 100% !important;
  }

  .show-catelog .col-md-3 {
    flex: 0 0 auto;
    width: 30%;
  }

  .fixed-topheader {
    width: 100% !important;
  }

  .user-level-tophed {
    top: -48px;
  }
}

@media screen and (min-width: 1200px) {
  .offcanvas-start {
    display: none;
  }
}

@media only screen and (min-width: 650px) and (max-width: 770px) {
  .reward-paypal-rd {
    height: 93px;
  }
  .showpop {
    position: unset;
    width: 100%;
    margin-top: -13px;
    margin-bottom: 12px;
  }

  .contact-us {
    margin-right: 0px !important;
    margin-left: 15px !important;
  }

  .user-level-tophed {
    margin-top: 26px !important;
    position: unset;
    top: unset;
    z-index: unset;
  }

  .level-box-container {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .contact-us {
    margin-right: 0px !important;
    margin-left: 15px !important;
  }

  .profile-div {
    margin: 8px !important;
  }

  .fixed-sidenav-body {
    margin-top: 12rem !important;
  }

  .profile-email {
    padding-left: 0px;
  }

  .profile-name {
    font-size: 14px;
  }

  .point-detail .col-md-3 {
    width: auto;
  }

  .survey-time {
    padding: 0px;
  }

  .form-heading {
    margin-bottom: 16px;
  }

  .loginbtn {
    margin-top: 13px !important;
  }

  .select-profile-photo i {
    margin-top: -33px !important;
    margin-left: 55px;
  }

  .user-level-tophed {
    position: relative;
    top: -24px;
    z-index: 1;
  }
}

@media only screen and (min-width: 725px) and (max-width: 1000px) {
  
  .landingpage-data {
    margin-top: 37px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
  }

  .notselected-card {
    top: 35px;
  }
}

/* Apply Firefox-specific styles */
@supports (-moz-appearance: none) {
  .fixed-topheader {
    width: -moz-available;
  }
  .redeem-card .card-image {
    width: -moz-available;
    
  }
  .reward-paypal-rd {
    height: 90px !important;
  }

  .reward-flag-login {
    left: 13.5rem;
  }
  .forgot-password-box {
    width: -moz-available;
  }
}

@supports (-webkit-appearance: none) {
  .fixed-topheader {
    width: 74%;
  }
}